

.table-container {
  max-height: 100vh; /* Adjust the maximum height as needed */
  overflow-y: auto; /* Add vertical scroll when content exceeds max height */
  overflow-x: auto;

}


.non-responsive-table {
  width: 100%; /* Optional: Set a fixed width for the table if needed */
  table-layout: auto; /* Allow column width to adjust based on content */
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
}

/* Apply white-space: nowrap to table cells */
.non-responsive-table th,
.non-responsive-table td {
  white-space: nowrap;
}

/* Apply overflow-x: auto to content divs within table cells */
.non-responsive-table div {
  overflow-x: auto;
  width: 100%; /* Allow the content div to expand */
  padding: 8px;
  text-align: center;
  color: #FC92F1;
}